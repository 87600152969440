/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'


const ContactPage = ({ data: { contactPage }, location}) => {
  const { data: contactPageData } = contactPage || {}

  const {
    page_title,
    description,
    phone_description,
    page_description,
    email_description,
  } = contactPageData

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={page_title}
        desc={description}
     />
      {/* Hero Section */}
      <section className="hero hero-page">
        {contactPageData.top.localFile && <Img fluid={contactPageData.top.localFile.childImageSharp.fluid} className="hero-banner" />}
        {!contactPageData.top.localFile &&
          <div style={{background: "url(/img/staff-banner.jpg)"}} className="hero-banner"></div>
        }
        <div className="container">
          <div className="row">
            <div className="col-xl-8 text-content">
              <BreadCrumb path={[{ label: 'Kursy', to: `/kursy` }]} />
              <h1>{page_title}</h1>
              <div className="row" >
                <p className="col-lg-8" dangerouslySetInnerHTML={{ __html: page_description.html }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="contact-icon">
                <div className="icon icon-map-1"></div>
              </div>
              <h3>Adres</h3>
              <p>Ignacego Daszyńskiego 9<br />85-791 Bydgoszcz<br /><strong>Polska</strong></p>
            </div>
            <div className="col-md-4">
              <div className="contact-icon">
                <div className="icon icon-support"></div>
              </div>
              <h3>Telefon</h3>
              <p>{phone_description}</p>
              <p><strong>+48 52 344-55-55</strong></p>
              <p><strong>+48 600 554-555</strong></p>
            </div>
            <div className="col-md-4">
              <div className="contact-icon">
                <div className="icon icon-envelope"></div>
              </div>
              <h3>Poczta email</h3>
              <p>{email_description}</p>
              <ul className="list-style-none">
                <li><strong><a href="mailto:">kontakt@oxfordon.pl</a></strong></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contact">
        <div id="map">
          <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBjeUHWOa93CD3xkBDSLwnCxRvvTymBAI0&q=Oxfordon, Bydgoszcz" width="100%" height="100%" />
        </div>
      </section>
    </>
  )
}

export default ContactPage

ContactPage.propTypes = {
}

export const pageQuery = graphql`
  query ContactPageQuery {
    contactPage: prismicContact {
      data {
        page_title
        description,
        page_description {
            html
        },  
        phone_description,
        email_description,
        top {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1900, quality: 90) {
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
      }
    }
  }
`
