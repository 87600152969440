import React from 'react'
import PropTypes from 'prop-types'

import LocalizedLink from '../LocalizedLink'

const BreadCrumb = ({ path }) => (
  <nav aria-label="breadcrumb" role="navigation">
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><LocalizedLink to="/">Home</LocalizedLink></li>
      {path.map((item, index) => (
        <li key={item.to} aria-current="page" className={index === path.length - 1 ? "breadcrumb-item active" : "breadcrumb-item"}>
          {index === path.length - 1 ? item.label : <LocalizedLink to={item.to}>{item.label}</LocalizedLink>}
        </li>
      ))}
    </ol>
  </nav>
)

BreadCrumb.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string, label: PropTypes.string })),
}

export default BreadCrumb
